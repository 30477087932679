var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('v-app', [_c('router-view', {
    key: _vm.$i18n.locale
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }