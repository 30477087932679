<template>
    <div id="app">
        <v-app>
            <router-view :key="$i18n.locale" />
        </v-app>
    </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
    data() {
        return {
            timer: null,
            latest: Date.now(),
        };
    },
    computed: {
        refreshToken() {
            return this.$store.state.refreshToken;
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return JSON.parse(atob(this.refreshToken?.split(".")[1] || "") || "{}");
        },
    },
    created() {
        this.init();
        const { refreshToken, accessToken } = this;
        if (refreshToken && accessToken) {
            this.login({ refreshToken, accessToken });
        }
        this.getUser();
        this.getBans();
    },
    watch: {
        accessToken() {
            if (!this.accessToken) {
                this.setUser({ user: undefined });
                alert("로그아웃 되었습니다");
                this.$router.push("/");
            } else {
                this.getUser();
                this.getBans();
            }
        },
    },
    methods: {
        ...mapMutations(["login", "setUser"]),
        ...mapActions(["getUser", "getSetting__v1", "getBans"]),
        init() {
            document.addEventListener("mousemove", this.reset, false);
            document.addEventListener("mousedown", this.reset, false);
            document.addEventListener("keypress", this.reset, false);
            document.addEventListener("touchmove", this.reset, false);
            document.addEventListener("onscroll", this.reset, false);

            this.onTimer();
            this.getSetting__v1();
        },
        reset() {
            this.latest = Date.now();
        },
        onTimer() {
            // clearTimeout();
            // this.timer = setTimeout(() => {
            //     if (24 * 60 * 60 * 1000 < Date.now() - this.latest || this.payload.exp * 1000 <= Date.now()) {
            //         this.$store.dispatch("logout");
            //     }
            //     this.onTimer();
            // }, 1000);
        },
    },
};
</script>